import { authRequest } from './api/auth';

class AiBotsService {
    select_list() {
        // return authRequest.get(`/chatgpt/upgpt/aibotselectlist/`);
        return authRequest.get(`/upgpt/v2/bot/aibotselectlist/`);
    }
    list() {
        // return authRequest.get(`/chatgpt/upgpt/aibotlist/`);
        return authRequest.get(`/upgpt/v2/bot/aibotlist/`);
    }
    active(id,data) {
        // return authRequest.put(`/chatgpt/upgpt/aibotactive/${id}/`,data);
        return authRequest.put(`/upgpt/v2/bot/aibotactive/${id}/`,data);
    }
    create(data) {
        // return authRequest.post(`/chatgpt/upgpt/aibotlist/`,data);
        return authRequest.post(`/upgpt/v2/bot/aibotlist/`,data);
    }
    detail(id) {
        // return authRequest.get(`/chatgpt/upgpt/aibotdetail/${id}`);
        return authRequest.get(`/upgpt/v2/bot/aibotdetail/${id}`);
    }
    edit(id,data) {
        // return authRequest.put(`/chatgpt/upgpt/aibotdetail/${id}/`,data);
        return authRequest.put(`/upgpt/v2/bot/aibotdetail/${id}/`,data);
    }
    delete(id) {
        // return authRequest.delete(`/chatgpt/upgpt/aibotdetail/${id}`);
        return authRequest.delete(`/upgpt/v2/bot/aibotdetail/${id}`);
    }
    get_upchat_inbox() {
        let data = {
            "api_type" : "get_inboxes_list",
        }
        return authRequest.post(`/chatwoot/gpt/`,data);
    }
    add_image_attribute_table(data){
        // return authRequest.post(`/chatgpt/upgpt/add_image_attribute/`,data);
        return authRequest.post(`/upgpt/v2/upload/addimageattribute/`,data);
    }
    get_image_attribute_table(data){
        // return authRequest.post(`/chatgpt/upgpt/image_attribute/`,data);
        return authRequest.post(`/upgpt/v2/upload/imageattribute/`,data);
    }
    get_image_attribute_header(data){
        // return authRequest.post(`/chatgpt/upgpt/flexfield_header/`,data);
        return authRequest.post(`/upgpt/v2/flexfield/header/`,data);
    }
    edit_image_attribute_table(id,data) {
        // return authRequest.put(`/chatgpt/upgpt/image_attribute/${id}/`,data);
        return authRequest.put(`/upgpt/v2/upload/imageattribute/${id}/`,data);
    }
    delete_image_attribute_table(id) {
        // return authRequest.delete(`/chatgpt/upgpt/image_attribute/${id}`);
        return authRequest.delete(`/upgpt/v2/upload/imageattribute/${id}`);
    }
    field_list(){
        // return authRequest.get(`/chatgpt/upgpt/flexfieldmetadata/`);
        return authRequest.get(`/upgpt/v2/flexfield/metadata/`);
    }
}
export default new AiBotsService();
import { authRequest } from './api/auth';

class UpGPTUploadStatusDataService {
    list(id) {
        // return authRequest.get(`/chatgpt/upgpt/uploadstatus/?knowledge_id=${id}`);
        return authRequest.get(`/upgpt/v2/upload/uploadstatus/?knowledge_id=${id}`);
    }
    bulk_delete(data) {
        // return authRequest.post(`/chatgpt/upgpt/uploadstatus/bulkdelete/`,data);
        return authRequest.post(`/upgpt/v2/upload/uploadstatus/`,data);
    }

}

export default new UpGPTUploadStatusDataService();
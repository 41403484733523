import { authRequest } from './api/auth';

class UpMeetingService {
    getMeetingRecapList(page,search,pageitem,sortby){
        return authRequest.get(`copilot/upgpt/getmeetingrecaplist/?page=${page}&search=${search}&pageitem=${pageitem}&sortby=${sortby}`);
    }
    createMeetingRecap() {
        return authRequest.post(`copilot/upgpt/getmeetingrecaplist/`);
    }
    deleteMeetingRecap(id) {
        return authRequest.delete(`copilot/upgpt/editdeletemeetingrecap/${id}/`);
    }
    editMeetingRecap(data) {
        return authRequest.put(`copilot/upgpt/editdeletemeetingrecap/${data.id}/`,data);
    }
    getMeetingRecapDetail(id){
        return authRequest.get(`copilot/upgpt/getmeetingrecapdetail/${id}/`);
    }
    giveMeSummary(data,key){
        let tenant = window.localStorage.getItem('tenant');
        return authRequest.post(`${tenant}/api/copilot/upgpt/summarizemeeting/?apikey=${key}`,data);
    }
    opptyList(keyword,id){
        return authRequest.get(`copilot/upgpt/opptsearchlist/?keyword=${keyword}&id=${id}`);
    }
    updateCRM(data,key){
        let tenant = window.localStorage.getItem('tenant');
        return authRequest.post(`${tenant}/api/copilot/upgpt/updatecrm/?apikey=${key}`,data);
    }
    JotAINoteBook(data,key){
        let tenant = window.localStorage.getItem('tenant');
        return authRequest.post(`${tenant}/api/copilot/upgpt/jotainotebook/?apikey=${key}`,data);
    }
    getAiNotebookList() {
        return authRequest.get(`copilot/upgpt/setgetainotebook/`);
    }
    createAiNotebook(data) {
        return authRequest.post(`copilot/upgpt/setgetainotebook/`,data);
    }
    getAiNotebookDeteail(id) {
        return authRequest.get(`copilot/upgpt/getainotebookfielddetail/${id}/`);
    }
    editAiNotebook(data) {
        return authRequest.put(`copilot/upgpt/editdeleteainotebook/${data.id}/`,data);
    }
    editAiNotebookFields(data) {
        return authRequest.put(`copilot/upgpt/editinotebookfields/${data.id}/`,data);
    }
    deleteAiNotebook(id) {
        return authRequest.delete(`copilot/upgpt/editdeleteainotebook/${id}/`);
    }
    AiMeetingMailGenerate(data,key){
        let tenant = window.localStorage.getItem('tenant');
        return authRequest.post(`${tenant}/api/copilot/upgpt/aimeetingmailgenerate/?apikey=${key}`,data);
    }
    AiTasksGenerate(data,key){
        let tenant = window.localStorage.getItem('tenant');
        return authRequest.post(`${tenant}/api/copilot/upgpt/aitaskgenerate/?apikey=${key}`,data);
    }
    AIAnalysisImage(data,key){
        let tenant = window.localStorage.getItem('tenant');
        return authRequest.post(`${tenant}/api/copilot/upgpt/aireplymessage/?apikey=${key}`,data);
    }
}
export default new UpMeetingService();
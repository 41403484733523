const fetchStreamingBlobAndDownload = async (blob_url, file_name) => {
    const PUBLIC_URL = process.env.VUE_APP_API_BASE_URL;
    const BASE_URL = PUBLIC_URL + '/tenant';
    const TENANT = 'tenant';
    const ACCESS_TOKEN = 'access_token';
    const response = await fetch(`${BASE_URL}/${window.localStorage.getItem(TENANT)}/api/download/signed/?blob_name=${blob_url}`, 
    {
        headers: {Authorization: `Bearer ${window.localStorage.getItem(ACCESS_TOKEN)}`}, 
        responseType: 'blob'
    });

    // 取得 ReadableStream (只有fetch的response有body)
    const reader = response.body.getReader();
    const chunks = [];

    let finish = false
    while (!finish) {
        const { done, value } = await reader.read();
        if (done) {
            finish = done
            break;
        }
        chunks.push(value);
    }

    // 合併 Uint8Array 並建立 Blob
    const blob = new Blob(chunks, { type: response.headers.get('Content-Type') });

    // 產生下載連結
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = file_name;
    document.body.appendChild(a);
    a.click();

    // 清理
    window.URL.revokeObjectURL(url);
    document.body.removeChild(a);
}

export { fetchStreamingBlobAndDownload };